'use client';

import { node, object } from 'prop-types';
import Image from 'next/image';
import { useMemo } from 'react';

import { useSchemes } from '@personly/libs-providers';

const Organization = ({ children, organization }) => {
  const { schemeHandle } = useSchemes();

  const { fileSources } = organization;
  const fileSource = fileSources[schemeHandle];

  const image = useMemo(() => {
    if (!fileSource) return null;

    const { fileName, metadata } = fileSource;
    const { height, width } = metadata;
    const src = `/images/organizations/${fileName}`;

    return { src, height, width, alt: fileName };
  }, [fileSource]);

  return (
    <div className="flex flex-row p-2">
      {image && (
        <div className="flex pr-2 w-2/5">
          <Image
            alt={image.alt}
            src={image.src}
            height={image.height}
            width={image.width}
            className="h-auto w-full"
            sizes="100vw"
          />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

Organization.propTypes = {
  children: node.isRequired,
  organization: object.isRequired,
};

export default Organization;
